import { ReactComponent as LeetCodeIcon } from "./leetcode.svg";
import { ReactComponent as LinkedInIcon } from "./linkedin.svg";
import { ReactComponent as GitHubIcon } from "./github.svg";

export default function HeroSection() {
  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content--box">
        <div className="flex-row">
          <img src="./img/nyu_logo.png" alt="NYU Logo" style={{ width: '41px', height: '59px', marginRight: '10px' }}  />
          <img src="./img/spglobal_logo.png" alt="S&P Global Logo" style={{ width: '146px', height: '58px', marginRight: '10px' }}  />
          <img src="./img/zomato_logo.png" alt="Zomato Logo" style={{ width: '60px', height: '60px', marginRight: '20px' }}  />
          <img src="./img/nsit_logo.png" alt="NSIT Logo" style={{ width: '60px', height: '60px' }}  />
        </div>
        <br/>
        <h2 className="text-center">NYU MS '25 | 2+ Years Software Engineering Experience | Ex-Cloud Engineer Intern @ S&P Global | Ex-SDE II @ Zomato | NSIT '21</h2>
        <br/><br/>

        <div className="hero--section--content">    
          <p className="section--title">Hey, I'm Sarthak</p>
          <h1 className="hero--section--title">
            <span className="hero--section-title--color">MS CS Student</span>{" "}
            <br />
            New York University
          </h1>
          <p className="hero--section-description">
            I am a second-year MS Computer Science graduate student at New York University with a strong background in software development and cloud engineering. In my previous role at Zomato, I served as a Software Engineer on the Hyperpure tech team, enhancing system efficiency and user experience through backend and frontend development. Additionally, as a Cloud Engineer Intern at S&P Global, I contributed to the growth of cloud solutions, supporting cross-functional technology teams in delivering business value with speed and efficiency. I am currently seeking 2025 New Grad Software Engineering opportunities. Excited to connect!
          </p>
        </div>
        <div className="flex-row">
          <a href="https://drive.google.com/file/d/1iF9RmH36LuBT060-anjRUCMSfLWilDRs/view?usp=sharing" target="_blank" rel="noopener noreferrer">
            <img src="./img/resume.png" alt="Resume" style={{ width: '50px', height: '50px' }} className="profile-btn" />
          </a>

          <a href="https://www.linkedin.com/in/sarthakgoel97/" target="_blank" rel="noopener noreferrer">
            <LinkedInIcon className="profile-btn"/>
          </a>

          <a href="https://github.com/sarthakgoel1997" target="_blank" rel="noopener noreferrer">
            <GitHubIcon className="profile-btn" />
          </a>
          
          <a href="mailto:sarthakgoel_97@hotmail.com" target="_blank" rel="noopener noreferrer">
            <div className="profile-btn">
              <img src="./img/email.png" alt="Email" style={{ width: '50px', height: '50px' }}  />
            </div>
          </a>

          <a href="https://leetcode.com/u/sarthak97/" target="_blank" rel="noopener noreferrer">
            <LeetCodeIcon className="profile-btn" />
          </a>
        </div>
      </div>
      <div className="hero--section--img">
        <img src="./img/sarthak.png" alt="Hero Section" />
      </div>
    </section>
  );
}
